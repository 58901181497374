.results-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

thead {
    background-color: #f8f8f8;
}

th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

h2 {
    margin-top: 20px;
}